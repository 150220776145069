.w-pc-portal-login-screen{
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    /* background-color: purple; */
}

.w-pc-portal-login-screen>.wrapper{
    height: 100%;
    width: 100%;
    /* background-color: rgb(243, 243, 243); */
}
.w-pc-portal-login-screen>.wrapper.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-pc-portal-login-screen>.wrapper>h1{
    box-sizing: border-box;
    font-size: 5vh;
    margin-bottom: 4vh;
    color: rgb(48, 48, 48);
}
.w-pc-portal-login-screen>.wrapper>.frame.title{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 25vh;
    width: 25vh;
    overflow: hidden;
}
.w-pc-portal-login-screen>.wrapper>.frame.title>img{
    height: 120%;
}


.w-pc-portal-login-screen>.wrapper.display>
.form-container{
    box-sizing: border-box;
    /* background-color: red; */
}
.w-pc-portal-login-screen>.wrapper.display>
.form-container.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-pc-portal-login-screen>.wrapper.display>.form-container>
.login-form{
    box-sizing: border-box;
    /* border: 1px dashed black; */
    /* background-color: red; */
}
.w-pc-portal-login-screen>.wrapper.display>.form-container>
.login-form.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}


.w-pc-portal-login-screen>.wrapper.display>.form-container>.login-form>
input{
    box-sizing: border-box;
    margin-bottom: 2vh;
    text-decoration: none;
    outline: none;
    border: 0.4px solid rgb(89, 89, 89);
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    width: 20vw;
    /* box-shadow: 0px 0px 2px; */
}

.w-pc-portal-login-screen>.wrapper.display>.form-container>.login-form>
button{
    box-sizing: border-box;
    border: 0px;
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    margin-top: 2vh;
    width: 20vw;
    background-color: rgb(22, 24, 62);
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-out;
}
.w-pc-portal-login-screen>.wrapper.display>.form-container>.login-form>button:hover{
    /* background-color: rgb(34, 34, 34); */
    background-color: rgb(34, 37, 102);
    color: #fff;
}

/* mobile */
.w-mob-portal-login-screen {
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    /* background-color: purple; */
}

.w-mob-portal-login-screen>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: rgb(243, 243, 243); */
}

.w-mob-portal-login-screen>.wrapper.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-login-screen>.wrapper>h1 {
    box-sizing: border-box;
    font-size: 5vh;
    margin-bottom: 4vh;
    color: rgb(48, 48, 48);
}

.w-mob-portal-login-screen>.wrapper.display>.form-container {
    box-sizing: border-box;
    /* background-color: red; */
}

.w-mob-portal-login-screen>.wrapper.display>.form-container.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-login-screen>.wrapper.display>.form-container>.login-form {
    box-sizing: border-box;
    /* border: 1px dashed black; */
    /* background-color: red; */
}

.w-mob-portal-login-screen>.wrapper.display>.form-container>.login-form.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}


.w-mob-portal-login-screen>.wrapper.display>.form-container>.login-form>input {
    box-sizing: border-box;
    margin-bottom: 2vh;
    text-decoration: none;
    outline: none;
    border: 0.4px solid rgb(89, 89, 89);
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    width: 65vw;
    /* box-shadow: 0px 0px 2px; */
}

.w-mob-portal-login-screen>.wrapper.display>.form-container>.login-form>button {
    box-sizing: border-box;
    border: 0px;
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    margin-top: 2vh;
    width: 20vw;
    background-color: rgb(71, 71, 71);
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-out;
}

.w-mob-portal-login-screen>.wrapper.display>.form-container>.login-form>button:hover {
    background-color: rgb(34, 34, 34);
    color: #fff;
}


/* tab device */
.w-tab-portal-login-screen {
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    /* background-color: purple; */
}

.w-tab-portal-login-screen>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: rgb(243, 243, 243); */
}

.w-tab-portal-login-screen>.wrapper.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-login-screen>.wrapper>h1 {
    box-sizing: border-box;
    font-size: 5vh;
    margin-bottom: 4vh;
    color: rgb(48, 48, 48);
}

.w-tab-portal-login-screen>.wrapper.display>.form-container {
    box-sizing: border-box;
    /* background-color: red; */
}

.w-tab-portal-login-screen>.wrapper.display>.form-container.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-login-screen>.wrapper.display>.form-container>.login-form {
    box-sizing: border-box;
    /* border: 1px dashed black; */
    /* background-color: red; */
}

.w-tab-portal-login-screen>.wrapper.display>.form-container>.login-form.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}


.w-tab-portal-login-screen>.wrapper.display>.form-container>.login-form>input {
    box-sizing: border-box;
    margin-bottom: 2vh;
    text-decoration: none;
    outline: none;
    border: 0.4px solid rgb(89, 89, 89);
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    width: 40vw;
    /* box-shadow: 0px 0px 2px; */
}

.w-tab-portal-login-screen>.wrapper.display>.form-container>.login-form>button {
    box-sizing: border-box;
    border: 0px;
    border-radius: 6px;
    padding-block: 1.9vh;
    padding-inline: 2vh;
    margin-top: 2vh;
    width: 20vw;
    background-color: rgb(71, 71, 71);
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-out;
}

.w-tab-portal-login-screen>.wrapper.display>.form-container>.login-form>button:hover {
    background-color: rgb(34, 34, 34);
    color: #fff;
}