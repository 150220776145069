
.component.loading{
    position: fixed;
    width: 100%;
    height: 100%;
    /* background-color: rgba(128, 128, 128, 0.169); */
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #dedede;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}

.loader::after {
    animation-delay: 1s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}