.w-pc-subscription-screen2 {
    /* height: 100vh; */
    width: 100%;
    padding-bottom: 10vh;
}

.w-pc-subscription-screen2>
.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 5vw;
    box-sizing: border-box;
    padding-top: 10vh
}

.w-pc-subscription-screen2>
.wrapper>
.screen-title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 6vh;
}
.w-pc-subscription-screen2>
.wrapper>
.screen-title>
h1{
    font-size: 8vh;
}

.w-pc-subscription-screen2>
.wrapper>
.screen-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-pc-subscription-screen2>
.wrapper>
.screen-content>
.conveyor {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 35vh;
    box-sizing: border-box;
    width: 100%;
}

.w-pc-subscription-screen2>
.wrapper>
.screen-content>
.conveyor>
.grid-item{
    /* border: 0.5px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-pc-subscription-screen2>
.wrapper>
.screen-content>
.conveyor>
.grid-item>
.frame{
    height: 90%;
    width: 80%;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 2vh;
    /* border: 1px solid green; */

}

.w-pc-subscription-screen2>
.wrapper>
.screen-content>
.conveyor>
.grid-item>
.frame>
img{
    height: 40vh;
    border-radius: 10px;
}

.w-pc-subscription-screen2>
.wrapper>
.screen-content>
.conveyor>
.grid-item>
h2{
    font-size: 3vh;
    text-align: center;
    /* border: 1px solid red; */
}


/* tab */
.w-tab-subscription-screen2 {
    /* height: 100vh; */
    width: 100%;
    padding-bottom: 10vh;
}

.w-tab-subscription-screen2>.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 5vw;
    box-sizing: border-box;
    padding-top: 10vh
}

.w-tab-subscription-screen2>.wrapper>.screen-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 6vh;
}

.w-tab-subscription-screen2>.wrapper>.screen-title>h1 {
    font-size: 8vw;
}

.w-tab-subscription-screen2>.wrapper>.screen-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-tab-subscription-screen2>.wrapper>.screen-content>.conveyor {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 35vw;
    box-sizing: border-box;
    width: 100%;
}

.w-tab-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item {
    /* border: 0.5px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-tab-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>.frame {
    height: 90%;
    width: 90%;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 2vw;
    /* border: 1px solid green; */

}

.w-tab-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>.frame>img {
    height: 100%;
    border-radius: 10px;
}

.w-tab-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>h2 {
    font-size: 3vw;
    text-align: center;
    /* border: 1px solid red; */
}

/* mobile */
.w-mob-subscription-screen2 {
    /* height: 100vh; */
    width: 100%;
    padding-bottom: 10vh;
}

.w-mob-subscription-screen2>.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 5vw;
    box-sizing: border-box;
    padding-top: 10vh
}

.w-mob-subscription-screen2>.wrapper>.screen-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-bottom: 6vh;
}

.w-mob-subscription-screen2>.wrapper>.screen-title>h1 {
    font-size: 8vw;
}

.w-mob-subscription-screen2>.wrapper>.screen-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-mob-subscription-screen2>.wrapper>.screen-content>.conveyor {
    display: grid;
    grid-template-columns: 30vh 30vh 30vh;
    grid-auto-rows: 60vw;
    box-sizing: border-box;
    width: 100vh;
    overflow-x: auto;
    /* Enable horizontal scrolling */
        white-space: nowrap;
    /* Prevent line breaks */
    /* background-color: red; */
}

.w-mob-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item {
    /* border: 0.5px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vh;
    flex: 0 0 auto;
    /* border: 1px solid yellow; */

}

.w-mob-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>.frame {
    height: 25vh;
    width: 25vh;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 2vw;
    /* border: 1px solid green; */

}

.w-mob-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>.frame>img {
    height: 100%;
    border-radius: 10px;
}

.w-mob-subscription-screen2>.wrapper>.screen-content>.conveyor>.grid-item>h2 {
    font-size: 5vw;
    text-align: center;
    /* border: 1px solid red; */
}