.no-scroll {
    overflow: hidden;
}
.w-pc-portal-products-screen{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-pc-portal-products-screen>.navbar{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}
.w-pc-portal-products-screen>.navbar>.box{
    box-sizing: border-box;
    height: 100%;
}
.w-pc-portal-products-screen>.navbar>.box.bar-1{
box-sizing: border-box;
}
.w-pc-portal-products-screen>.navbar>.box.bar-2{
    box-sizing: border-box;
}
.w-pc-portal-products-screen>.navbar>.box.bar-3{
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-products-screen>.navbar>.box.bar-3>.frame{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}
.w-pc-portal-products-screen>.navbar>.box.bar-3>.frame>img{
    height: 50%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}
.w-pc-portal-products-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-pc-portal-products-screen>.wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
}


/* wrapper - title */
.w-pc-portal-products-screen>.wrapper>.actions.bar{
    box-sizing: border-box;
    width: 90vw;
    height: 13vh;
    display: grid;
    grid-template-columns: 30% 60% 5% 5%;
    /* border: 1px dashed gray; */
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action{
    box-sizing: border-box;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.title>h1{
    font-size: 6vh;
}

.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search{
    box-sizing:  border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 2vw;
    /* background-color: red; */
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper{
    box-sizing: border-box;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    
    height: 40%;
    width: 100%;
    /* background-color: red; */
    position: relative;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group{
    position: absolute;
    box-sizing: border-box;
    height: auto;
    width: 20vw;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.458);
    transition: 0.3s ease-out;
    padding: 2vh;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title{
    font-size: 20px;
    margin-bottom: 15px;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title.set2{
    margin-top: 15px;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor{
    font-size: 13px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.3s ease-out;
} 
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor:hover{
    color: rgb(156, 152, 152);
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>input{
    outline: none;
    border: none;
    padding-block: 0.5vh;
    padding-inline: 1vw;
    width: 55%;
    box-sizing: border-box;
    letter-spacing: 1.5px;
    height: 100%;
    border: 0.5px solid gray;

}

.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.product-group{
    width: 30%;
    height: 100%;
    /* box-sizing: border-box; */
    /* background-color: red; */
    font-size: small;
    background-color: #202020;
    color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-left: 0.5px solid gray;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.search{
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    /* background-color: red; */
    font-size: small;
    background-color: #2d2c2c;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-right: 0.5px solid gray;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p:hover{
    background-color: rgba(62, 62, 62, 0.943);
    transition: 0.3s ease-out;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add{
    box-sizing: border-box;
    padding-inline: 1vw;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add>.frame.icon{
    width:5vh;
    height: 5vh;
    /* overflow: hidden; */
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add>.frame.icon>img{
    height: 100%;
}

.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add.basket>.frame.icon.basket{
    /* box-sizing: border-box;
    padding-inline: 1vw; */
    position: relative;
    cursor: pointer;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add.basket>.frame.icon>.basket.count{
    position: absolute;
    box-sizing: border-box;
    /* background-color: red; */
    top: -50%;
    left: 50%;
}
.w-pc-portal-products-screen>.wrapper>.actions.bar>.action.add.basket>.frame.icon>.basket.count>p{
    font-size: 2vh;
    height: 4vh;
    width: 4vh;
    color: #fff;
    background-color:#c41717;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* wrapper - dashboard */
/* .w-pc-portal-products-screen>.wrapper>.cardholder.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
} */
.w-pc-portal-products-screen>.wrapper>.products.cardholder{
    width: 90vw;
    height: 150vh;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Adjust to fit items */
    grid-auto-rows: 59vh;
    /* Let rows adapt based on content */
    gap: 10px;
    /* Gap between grid items */
    overflow: auto;
    /* Allow scrolling if items overflow */
    padding: 10px;
    /* border: 2px solid black; */
    box-sizing: border-box;
    /* Optional styling for visualization */
}
.w-pc-portal-products-screen>.wrapper>.cardholder.products>
.product.card{
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: vw;
    height: 20vh;
    padding-inline: 0.5vw;
    border: 1px dashed grey; */
    width:100%;
    height: 59vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */;
    
    /* Optional border for visibility */
    font-size: 1rem;
    text-align: center;
    padding-inline: 0.5vw;
    padding-block: 0.5vw;

    /* background-color: blue; */
}


.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>
.object-wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.3s ease-out;
    /* padding-block: 1vh; */
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.35);
    border-radius: 10px;
    transition: 0.3s ease-out;
    /* border: 0.5px solid black; */ 
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper:hover{
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.573);
    transition: 0.3s ease-out;
}

.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.icons{
    box-sizing: border-box;
    height: 5%;
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-inline: 1vh;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.icons>.frame{
    height: 100%;
    width: 3vh;
    /* background-color: green; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.icons>.frame>img{
    height: 100%;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.icons>p{
    /* background-color: blue; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100%;
}

.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images{
    box-sizing: border-box;
    width: 100%;
    height: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vh;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images
>.frame{
    box-sizing: border-box;
    width: 95%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px ;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images>.frame
>img{
    width: 100%;
}


.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts{
    box-sizing: border-box;
    width: 100%;
    height: 40%;
    /* border: 1px solid green; */
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>
.object-wrapper{
    box-sizing: border-box;
    height: 7vh;
    width: 100%;
    padding-block: 0.5vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>p.description{
    font-size: 11px;
    padding-inline: 2vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 10vh;
    /* border: 1px solid red; */
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>
.frame{
    height: 4vh;
    width: 4vh;
    overflow: hidden;
    margin-inline: 1vh;
    font-size: 3vh;;
}
.w-pc-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>.frame>
img{
    height: 100%;
}


/* product details */
.w-pc-portal-products-screen>.wrapper.display>.baskets.details{
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #2d2c2c98;
    backdrop-filter: blur(9px);


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details
>.wrapper.details{
    box-sizing: border-box;
    /* padding-block: 5vh; */
    height: 80%;
    width: 50%;
    /* box-shadow: 0px 0px 5px black; */
    background-color: #fff;
    border-radius: 15px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 5vh;
    padding-inline: 2vh;
    /* background-color: red; */
}

.w-pc-portal-products-screen>.wrapper.display>.baskets.details>h1{
    justify-self: center;
    color: #fff;
}


.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>
.basket.item.card{
    box-sizing: border-box;
    
    height: 7vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span{
    display: flex;
    flex-direction: row;
    /* background-color: #f1f1f14e; */
    width: 70%;
    justify-content: start;
    align-items: center;
    height: 90%;
    border-radius: 9px;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p{
    width: 33.33333%
}

.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card.title>.span{
    background-color: #f1f1f18c;
    padding-inline: 3vw;
}

.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.title{
    font-weight: bold;
    /* background-color: #f1f1f18c; */
}

.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.additionals{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(199, 196, 196, 0.493);;
    border-radius: 10px;
    margin-top: 10vh;
    padding-block: 3vh;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.additionals>.row{
    box-sizing: border-box;
    height: 5vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.additionals>.row>.span{
    width: 70%;
    /* background-color: aquamarine; */
    display: flex;
        flex-direction: row;
        /* background-color: #f1f1f14e; */
        width: 70%;
        justify-content: start;
        align-items: center;
        height: 90%;
        border-radius: 9px;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.additionals>.row>.span>p{
    width: 33.33333%;
    font-size: 2vh;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.button{
    width: 100%;
    box-sizing: border-box;
    padding-inline: 2vh;

    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    height: 5vh;
    margin-top: 5vh;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.button>p{
    padding-inline: 2vh;
    padding-block: 3vh;
    background-color: rgb(45, 45, 45);
    box-sizing: border-box;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-out;
}
.w-pc-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.button>p:hover{
    transition: 0.3s ease-out;
    background-color: rgb(67, 67, 67);
}



/* mobile device */
.no-scroll {
    overflow: hidden;
}

.w-mob-portal-products-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-mob-portal-products-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}

.w-mob-portal-products-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-mob-portal-products-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-mob-portal-products-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-mob-portal-products-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-products-screen>.navbar>.box.bar-3>.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-mob-portal-products-screen>.navbar>.box.bar-3>.frame>img {
    height: 45%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.w-mob-portal-products-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-mob-portal-products-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
}


/* wrapper - title */
.w-mob-portal-products-screen>.wrapper>.actions.bar {
    box-sizing: border-box;
    width: 95%;
    height: 35vh;
    display: grid;
    grid-template-rows: 5vh 15vh 10vh 5vh;
    /* border: 1px dashed gray; */
    /* background-color: blue; */
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action {
    box-sizing: border-box;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.title>h1 {
    font-size: 6vh;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding-inline: 2vw; */
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    height: 40%;
    width: 100%;
    /* background-color: red; */
    position: relative;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group {
    position: absolute;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.458);
    transition: 0.3s ease-out;
    padding: 2vh;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title {
    font-size: 20px;
    margin-bottom: 15px;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title.set2 {
    margin-top: 15px;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor {
    font-size: 13px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.3s ease-out;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor:hover {
    color: rgb(156, 152, 152);
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>input {
    outline: none;
    border: none;
    padding-block: 0.5vh;
    padding-inline: 1vw;
    width: 45%;
    box-sizing: border-box;
    letter-spacing: 1.5px;
    height: 100%;
    border: 0.5px solid gray;

}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.product-group {
    width: 40%;
    height: 100%;
    /* box-sizing: border-box; */
    /* background-color: red; */
    font-size: small;
    background-color: #202020;
    color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-left: 0.5px solid gray;
    text-overflow: ellipsis;
    font-size: 1.5vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.search {
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    /* background-color: red; */
    font-size: small;
    background-color: #2d2c2c;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-right: 0.5px solid gray;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p:hover {
    background-color: rgba(62, 62, 62, 0.943);
    transition: 0.3s ease-out;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add {
    box-sizing: border-box;
    padding-inline: 1vw;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add>.frame.icon {
    width: 5vh;
    height: 5vh;
    /* overflow: hidden; */
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add>.frame.icon>img {
    height: 100%;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket{
    /* background-color: pink; */
    position: fixed;
    bottom: 20px;
    right: 15px;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon.basket {
    /* box-sizing: border-box;
    padding-inline: 1vw; */
    position: relative;
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon>.basket.count {
    position: absolute;
    box-sizing: border-box;
    /* background-color: red; */
    top: -50%;
    left: 50%;
}

.w-mob-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon>.basket.count>p {
    font-size: 2vh;
    height: 4vh;
    width: 4vh;
    color: #fff;
    background-color: #c41717;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* wrapper - dashboard */
/* .w-mob-portal-products-screen>.wrapper>.cardholder.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
} */
.w-mob-portal-products-screen>.wrapper>.products.cardholder {
    width: 90vw;
    /* height: 100vh; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Adjust to fit items */
    grid-auto-rows: 55vh;
    /* Let rows adapt based on content */
    gap: 10px;
    /* Gap between grid items */
    /* overflow: auto; */
    /* Allow scrolling if items overflow */
    padding: 10px;
    /* border: 2px solid black; */
    box-sizing: border-box;
    /* Optional styling for visualization */
    /* background-color: black; */
}

.w-mob-portal-products-screen>.wrapper>.cardholder.products>.product.card {
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: vw;
    height: 20vh;
    padding-inline: 0.5vw;
    border: 1px dashed grey; */
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    ;

    /* Optional border for visibility */
    font-size: 1rem;
    text-align: center;
    padding-inline: 0.5vw;
    padding-block: 0.5vw;
    /* background-color: blue; */
}


.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.35);
    border-radius: 10px;
    transition: 0.3s ease-out;
    /* border: 0.5px solid black; */
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper:hover {
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.573);
    transition: 0.3s ease-out;
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images {
    box-sizing: border-box;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vh;
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    width: 95%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images>.frame>img {
    width: 100%;
}


.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts {
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    /* border: 1px solid green; */
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper {
    box-sizing: border-box;
    height: 7vh;
    width: 100%;
    padding-block: 0.5vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>.frame {
    height: 4vh;
    width: 4vh;
    overflow: hidden;
    margin-inline: 1vh;
    font-size: 3vh;
    ;
}

.w-mob-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>.frame>img {
    height: 100%;
}


/* product details */
.w-mob-portal-products-screen>.wrapper.display>.baskets.details {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #2d2c2c98;
    backdrop-filter: blur(9px);


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details {
    box-sizing: border-box;
    /* padding-block: 5vh; */
    height: 80%;
    width: 95%;
    /* box-shadow: 0px 0px 5px black; */
    background-color: #fff;
    border-radius: 15px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-block: 3vh;
    padding-inline: 2vh;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>h1 {
    justify-self: center;
    color: #fff;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card {
    box-sizing: border-box;

    height: 7vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span {
    display: flex;
    flex-direction: row;
    /* background-color: #f1f1f14e; */
    width: 95%;
    justify-content: start;
    align-items: center;
    height: 90%;
    border-radius: 9px;
}


.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p {
    width: 33.33333%;
    font-size: 1.4vh;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card.title>.span {
    background-color: #f1f1f18c;
    padding-inline: 3vw;
}

.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.title {
    font-weight: bold;
    /* background-color: #f1f1f18c; */
}
.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.product {
    width: 50%;
}
.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.quantity {
    width: 20%
}
.w-mob-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.price {
    width: 30%
}




/* tab device */
.no-scroll {
    overflow: hidden;
}

.w-tab-portal-products-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-tab-portal-products-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}

.w-tab-portal-products-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-tab-portal-products-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-tab-portal-products-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-tab-portal-products-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-products-screen>.navbar>.box.bar-3>.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-tab-portal-products-screen>.navbar>.box.bar-3>.frame>img {
    height: 45%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.w-tab-portal-products-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-tab-portal-products-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
}


/* wrapper - title */
.w-tab-portal-products-screen>.wrapper>.actions.bar {
    box-sizing: border-box;
    width: 95%;
    height: 35vh;
    display: grid;
    grid-template-rows: 5vh 15vh 10vh 5vh;
    /* border: 1px dashed gray; */
    /* background-color: blue; */
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action {
    box-sizing: border-box;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.title>h1 {
    font-size: 6vh;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding-inline: 2vw; */
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    height: 40%;
    width: 100%;
    /* background-color: red; */
    position: relative;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group {
    position: absolute;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.458);
    transition: 0.3s ease-out;
    padding: 2vh;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title {
    font-size: 20px;
    margin-bottom: 15px;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.title.set2 {
    margin-top: 15px;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor {
    font-size: 13px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.3s ease-out;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>div.floating.product-group>p.group.anchor:hover {
    color: rgb(156, 152, 152);
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>input {
    outline: none;
    border: none;
    padding-block: 0.5vh;
    padding-inline: 1vw;
    width: 45%;
    box-sizing: border-box;
    letter-spacing: 1.5px;
    height: 100%;
    border: 0.5px solid gray;

}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.product-group {
    width: 40%;
    height: 100%;
    /* box-sizing: border-box; */
    /* background-color: red; */
    font-size: small;
    background-color: #202020;
    color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-left: 0.5px solid gray;
    text-overflow: ellipsis;
    font-size: 1.5vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p.search {
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    /* background-color: red; */
    font-size: small;
    background-color: #2d2c2c;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-top: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    border-right: 0.5px solid gray;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.search>.input-wrapper>p:hover {
    background-color: rgba(62, 62, 62, 0.943);
    transition: 0.3s ease-out;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add {
    box-sizing: border-box;
    padding-inline: 1vw;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add>.frame.icon {
    width: 5vh;
    height: 5vh;
    /* overflow: hidden; */
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add>.frame.icon>img {
    height: 100%;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket {
    /* background-color: pink; */
    position: fixed;
    bottom: 20px;
    right: 15px;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon.basket {
    /* box-sizing: border-box;
    padding-inline: 1vw; */
    position: relative;
    cursor: pointer;
    width: 7vh;
    height: 7vh;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon>.basket.count {
    position: absolute;
    box-sizing: border-box;
    /* background-color: red; */
    top: -50%;
    left: 50%;
}

.w-tab-portal-products-screen>.wrapper>.actions.bar>.action.icons.bar>.action.add.basket>.frame.icon>.basket.count>p {
    font-size: 2vh;
    height: 4vh;
    width: 4vh;
    color: #fff;
    background-color: #c41717;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* wrapper - dashboard */
/* .w-tab-portal-products-screen>.wrapper>.cardholder.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
} */
.w-tab-portal-products-screen>.wrapper>.products.cardholder {
    width: 90vw;
    /* height: 100vh; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Adjust to fit items */
    grid-auto-rows: 55vh;
    /* Let rows adapt based on content */
    gap: 10px;
    /* Gap between grid items */
    /* overflow: auto; */
    /* Allow scrolling if items overflow */
    padding: 10px;
    /* border: 2px solid black; */
    box-sizing: border-box;
    /* Optional styling for visualization */
    /* background-color: black; */
}

.w-tab-portal-products-screen>.wrapper>.cardholder.products>.product.card {
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: vw;
    height: 20vh;
    padding-inline: 0.5vw;
    border: 1px dashed grey; */
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    ;

    /* Optional border for visibility */
    font-size: 1rem;
    text-align: center;
    padding-inline: 0.5vw;
    padding-block: 0.5vw;
    /* background-color: blue; */
}


.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.35);
    border-radius: 10px;
    transition: 0.3s ease-out;
    /* border: 0.5px solid black; */
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper:hover {
    box-shadow: 1px 3px 3px rgba(91, 91, 91, 0.573);
    transition: 0.3s ease-out;
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images {
    box-sizing: border-box;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vh;
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    width: 95%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.images>.frame>img {
    width: 100%;
}


.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts {
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    /* border: 1px solid green; */
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper {
    box-sizing: border-box;
    height: 7vh;
    width: 100%;
    padding-block: 0.5vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>.frame {
    height: 4vh;
    width: 4vh;
    overflow: hidden;
    margin-inline: 1vh;
    font-size: 3vh;
    ;
}

.w-tab-portal-products-screen>.wrapper>.products.cardholder>.product.card>.object-wrapper>.texts>.object-wrapper>.frame>img {
    height: 100%;
}


/* product details */
.w-tab-portal-products-screen>.wrapper.display>.baskets.details {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #2d2c2c98;
    backdrop-filter: blur(9px);


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details {
    box-sizing: border-box;
    /* padding-block: 5vh; */
    height: 80%;
    width: 95%;
    /* box-shadow: 0px 0px 5px black; */
    background-color: #fff;
    border-radius: 15px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-block: 3vh;
    padding-inline: 2vh;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>h1 {
    justify-self: center;
    color: #fff;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card {
    box-sizing: border-box;

    height: 7vh;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span {
    display: flex;
    flex-direction: row;
    /* background-color: #f1f1f14e; */
    width: 95%;
    justify-content: start;
    align-items: center;
    height: 90%;
    border-radius: 9px;
}


.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p {
    width: 33.33333%;
    font-size: 1.4vh;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card.title>.span {
    background-color: #f1f1f18c;
    padding-inline: 3vw;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.title {
    font-weight: bold;
    /* background-color: #f1f1f18c; */
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.product {
    width: 50%;
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.quantity {
    width: 20%
}

.w-tab-portal-products-screen>.wrapper.display>.baskets.details>.wrapper.details>.basket.item.card>.span>p.price {
    width: 30%
}