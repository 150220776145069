.w-pc-portal-home-screen{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-pc-portal-home-screen>.navbar{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}
.w-pc-portal-home-screen>.navbar>.box{
    box-sizing: border-box;
    height: 100%;
}
.w-pc-portal-home-screen>.navbar>.box.bar-1{
box-sizing: border-box;
}
.w-pc-portal-home-screen>.navbar>.box.bar-2{
    box-sizing: border-box;
}
.w-pc-portal-home-screen>.navbar>.box.bar-3{
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-home-screen>.navbar>.box.bar-3>.frame{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}
.w-pc-portal-home-screen>.navbar>.box.bar-3>.frame>img{
    height: 60%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}
.w-pc-portal-home-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-pc-portal-home-screen>.wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: vh;
}


/* wrapper - title */
.w-pc-portal-home-screen>.wrapper>h1{
    font-size: 6vh;
    margin-bottom: 4vh;
}
.w-pc-portal-home-screen>.wrapper>.frame.logo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 30vh;
    width: 30vh;

    overflow: hidden;
}
.w-pc-portal-home-screen>.wrapper>.frame.logo>img{
    height: 120%;
}


/* wrapper - dashboard */
.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
}
.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>
.card.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    padding-inline: 0.5vw;
    /* border: 1px dashed grey; */
}

.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>
.object-wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
    /* border: 0.5px solid black; */ 
}
.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover{
    box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.291);
    border-radius: 10px;
    transition: 0.3s ease-out;
    background: rgba(83, 83, 83, 0.132);
}

.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>
.frame{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
 
    overflow: hidden;
    width: 7vw;
    height: 7vw;
    /* background-color: blue; */
}

.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>
img{
    height: 100%;
    filter: invert(10%);
}


.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>
.texts{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
        /* border: 1px solid red; */
}

.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>
h2{
    text-align: center;
    font-size: larger;
}
.w-pc-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>
p{
    text-align: center;
    font-size: small;
    width: 100%;
    /* background-color: rgb(31, 31, 31);; */
}


/* mobile */
.w-mob-portal-home-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 135vh; */
    width: 100%;
    color: rgb(39, 39, 39);
    /* background-color: red; */
}

/* navbar */
.w-mob-portal-home-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-auto-rows: 15vh;
    padding-top: 0vh;
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(61, 60, 60); */
}

.w-mob-portal-home-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-mob-portal-home-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-mob-portal-home-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-mob-portal-home-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-home-screen>.navbar>.box.bar-3>.frame {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-mob-portal-home-screen>.navbar>.box.bar-3>.frame>img {
    height: 60%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}
.w-mob-portal-home-screen>.navbar>.box.bar-3>.frame>img:hover{
    filter: invert(25%);
    transition: 0.3s ease-out;
}

/* wrapper - title */
.w-mob-portal-home-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 5vh;
    /* background-color: red; */
}

.w-mob-portal-home-screen>.wrapper>h1 {
    font-size: 6vh;
    margin-bottom:2vh;
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 100vh;
    padding-inline: 2vw;
    /* background-color: blue; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22%;
    padding-inline: 0.5vw;
    margin-bottom: 1vh;;
    /* border: 1px dashed grey; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;

    user-select: none;
        /* Standard CSS property */
        -webkit-user-select: none;
        /* Safari/Chrome */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
    /* border: 0.5px solid black; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover {
    /* box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.463); */
    border-radius: 10px;
    background-color: rgba(225, 225, 225, 0.345);
    transition: 0.3s ease-out;
    background: rgba(83, 83, 83, 0.132);
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    width: 8vh;
    height: 8vh;
    /* background-color: blue; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>img {
    height: 100%;
    filter: invert(10%);
    user-select: none;
        /* Prevent image selection highlight */
        -webkit-user-drag: none;
        /* Prevent image dragging in Safari/Chrome */
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE/Edge */
}


.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
    /* border: 1px solid red; */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>h2 {
    text-align: center;
    font-size: larger;
    user-select: none;
        /* Standard CSS property */
        -webkit-user-select: none;
        /* Safari/Chrome */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
}

.w-mob-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>p {
    text-align: center;
    font-size: small;
    width: 100%;
    user-select: none; /* Standard CSS property */
    -webkit-user-select: none; /* Safari/Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    /* background-color: rgb(31, 31, 31);; */
}


/* tab device*/
.w-tab-portal-home-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-tab-portal-home-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-auto-rows: 15vh;
    padding-top: 2vh;
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(61, 60, 60); */
}

.w-tab-portal-home-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-tab-portal-home-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-tab-portal-home-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-tab-portal-home-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-home-screen>.navbar>.box.bar-3>.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-tab-portal-home-screen>.navbar>.box.bar-3>.frame>img {
    height: 40%;
    width: auto;
    transition: 0.3s ease-out;
}
.w-tab-portal-home-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}

.w-tab-portal-home-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.w-tab-portal-home-screen>.wrapper>h1 {
    font-size: 6vh;
    margin-bottom: 4vh;
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
    /* background-color: red; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    padding-inline: 0.5vw;
    /* border: 1px dashed grey; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
    /* border: 0.5px solid black; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover {
    box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.463);
    border-radius: 10px;
    transition: 0.3s ease-out;
    background: rgba(83, 83, 83, 0.132);
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    width: 7vw;
    height: 7vw;
    /* background-color: blue; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>img {
    height: 100%;
    filter: invert(10%);
}


.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
    /* border: 1px solid red; */
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>h2 {
    text-align: center;
    font-size: larger;
}

.w-tab-portal-home-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>p {
    text-align: center;
    font-size: small;
    width: 100%;
    /* background-color: rgb(31, 31, 31);; */
}