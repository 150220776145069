.w-pc-home-screen1{
    height: 100vh;
    width: 100%;
    display: grid;
}
.w-pc-home-screen1>.wrapper{
    display: grid;
    grid-template-columns: 50% 50%;
}

.w-pc-home-screen1>.wrapper>.tagline{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    /* border: 1px solid black; */
    padding-right: 5vw;
}
.w-pc-home-screen1>.wrapper>.tagline>h1{
    width: 70%;
    z-index: 90000;
    /* border: 1px solid red; */
}
.w-pc-home-screen1>.wrapper>.tagline>h1{
    font-size: 13vh;
}
/* .w-pc-home-screen1>.wrapper>.actions {
    border: 1px solid black;
} */
 .w-pc-home-screen1>.wrapper>.actions{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* padding-left: 20vh; */
 }
.w-pc-home-screen1>.wrapper>.actions>img{
    height: 40vh;
}


/* tab view */
.w-tab-home-screen1 {
    /* height: 80vh; */
    width: 100%;
    display: grid;
    padding-top: 13vh;
}

.w-tab-home-screen1>.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen1>.wrapper>.tagline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    /* padding-left: 10vh; */
}

.w-tab-home-screen1>.wrapper>.tagline>h1 {
    font-size: 6vh;
    /* border: 0.5px solid black; */
}

/* .w-tab-home-screen1>.wrapper>.actions {
    border: 1px solid black;
} */
.w-tab-home-screen1>.wrapper>.actions {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* padding-left: 20vh; */
}

.w-tab-home-screen1>.wrapper>.actions>img {
    height: 30vh;
}


/* mobile view */

.w-mob-home-screen1 {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 22vh;
    box-sizing: border-box;
}

.w-mob-home-screen1>.wrapper {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}

.w-mob-home-screen1>.wrapper>.tagline {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 8vw;
    width: 80%;
    /* border: 0.5px solid black; */
    height: 20vh;
}

.w-mob-home-screen1>.wrapper>.tagline>h1 {
    font-size: 6vh;
    z-index: 8000;
}

/* .w-mob-home-screen1>.wrapper>.actions {
    border: 1px solid black;
} */
.w-mob-home-screen1>.wrapper>.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 0.5px solid black; */
    height: 39vh;
    /* padding-left: 20vh; */
}

.w-mob-home-screen1>.wrapper>.actions>img {
    height: 30vh;
    
}