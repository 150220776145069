.w-pc-portal-products-edit-screen{
    position: fixed;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(28, 28, 28, 0.058);
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-pc-portal-products-edit-screen>.modal{
    height: 90%;
    width: 60%;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    overflow-y: auto;
    padding-top: 5vh;
    padding-inline: 3vh;
}


.w-pc-portal-products-edit-screen>.modal>.icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    height: 5vh;
    /* background-color: red; */
    width: 100%;
    margin-bottom: 2vh;

}
.w-pc-portal-products-edit-screen>.modal>.icons>.frame{
    width: 5vh;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    margin-left: 3vh;
    cursor: pointer;
}
.w-pc-portal-products-edit-screen>.modal>.icons>.frame>img{
    height: 100%;
}


.w-pc-portal-products-edit-screen>.modal>.detail.row{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 15vh;
    width: 100%;
    /* background-color: aqua; */
}
.w-pc-portal-products-edit-screen>.modal>.detail.row>p{
    padding-block: 1vh;
    font-size: 2.9vh;
}
.w-pc-portal-products-edit-screen>.modal>.detail.row>input{
    border: 0.5px solid rgb(179, 176, 176);
    outline: 0;
    border-radius: 5px;
    padding-inline: 1vh;
    padding-block: 2vh;
    width: 50vw;
}



.w-pc-portal-products-edit-screen>.modal>.buttons{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 10vh;
}
.w-pc-portal-products-edit-screen>.modal>.buttons>p{
    padding-block: 2vh;
    padding-inline: 2vh;
    width: 5vw;
    background-color: rgb(41, 40, 40);
    color: #fff;
    border-radius: 10px;
    margin-top: 10px;

}
