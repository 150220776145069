.w-pc-portal-transactions-screen{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-pc-portal-transactions-screen>.navbar{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}
.w-pc-portal-transactions-screen>.navbar>.box{
    box-sizing: border-box;
    height: 100%;
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-1{
box-sizing: border-box;
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-2{
    box-sizing: border-box;
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-3{
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-3>.frame{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-3>.frame>img{
    height: 60%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}
.w-pc-portal-transactions-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-pc-portal-transactions-screen>.wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
}


/* wrapper - title */
.w-pc-portal-transactions-screen>.wrapper>h1{
    font-size: 6vh;
    margin-bottom: 4vh;
}

.w-pc-portal-transactions-screen>.wrapper>.capsule-deck{
    width: 90vw;
    height: 65vh;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Adjust to fit items */
    grid-auto-rows: 30vh;
    /* Let rows adapt based on content */
    gap: 10px;
    /* Gap between grid items */
    overflow: auto;
    /* Allow scrolling if items overflow */
    padding: 10px;
    /* border: 2px solid black; */
    box-sizing: border-box;
    /* Optional styling for visualization */
}

.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule{
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    padding-inline: 0.5vw;
    padding-block: 0.5vw;
}

.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.181);
    border-radius: 10px;
    cursor: pointer;
    
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container:hover{
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.181);
    transition: 0.3s ease-out;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container
>.status{
    height: 20%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgb(78, 134, 57);
    color: #fff;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container
>.details{
    height: 80%;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 4vh;
}

.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container>.details
>.order-id{
    font-size: 3vh;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container>.details>
.items {
    font-size: 2.5vh;
    margin-bottom: 1.7vh;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container>.details>
.date {
    font-size: 2vh;
    align-self: start;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container>.details>
.price {
    font-size: 2vh;
    align-self: start;
}
.w-pc-portal-transactions-screen>.wrapper>.capsule-deck>.capsule>.container>.details>
.madeby {
    font-size: 2vh;
    align-self: start;
}










/* wrapper - dashboard */
.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
}
.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>
.card.dashboard-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    padding-inline: 0.5vw;
    /* border: 1px dashed grey; */
}

.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>
.object-wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
    /* border: 0.5px solid black; */ 
}
.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover{
    box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.463);
    border-radius: 10px;
    transition: 0.3s ease-out;
}

.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>
.frame{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
 
    overflow: hidden;
    width: 7vw;
    height: 7vw;
    /* background-color: blue; */
}

.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>
img{
    height: 100%;
    filter: invert(10%);
}


.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>
.texts{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
        /* border: 1px solid red; */
}

.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>
h2{
    text-align: center;
    font-size: larger;
}
.w-pc-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>
p{
    text-align: center;
    font-size: small;
    width: 100%;
    /* background-color: rgb(31, 31, 31);; */
}



/* mobile */
.w-mob-portal-transactions-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-mob-portal-transactions-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}

.w-mob-portal-transactions-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-3>.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-3>.frame>img {
    height: 45%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.w-mob-portal-transactions-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-mob-portal-transactions-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
    /* background-color: red; */
}


/* wrapper - title */
.w-mob-portal-transactions-screen>.wrapper>h1 {
    font-size: 6vh;
    margin-bottom: 4vh;
    /* background-color: blue; */
}


/* wrapper - dashboard */
.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    padding-inline: 0.5vw;
    /* border: 1px dashed grey; */
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover {
    box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.463);
    border-radius: 10px;
    transition: 0.3s ease-out;
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    width: 7vw;
    height: 7vw;
    /* background-color: blue; */
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>img {
    height: 100%;
    filter: invert(10%);
}


.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
    /* border: 1px solid red; */
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>h2 {
    text-align: center;
    font-size: larger;
}

.w-mob-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>p {
    text-align: center;
    font-size: small;
    width: 100%;
    /* background-color: rgb(31, 31, 31);; */
}



/* tab device */
.w-tab-portal-transactions-screen {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: rgb(39, 39, 39);
}

/* navbar */
.w-tab-portal-transactions-screen>.navbar {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-auto-rows: 14vh;
    /* padding-top: 2.5vh; */
    /* padding-block: 10%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 13vh; */
    width: 100%;
    /* background-color: rgb(246, 246, 246); */
}

.w-tab-portal-transactions-screen>.navbar>.box {
    box-sizing: border-box;
    height: 100%;
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-1 {
    box-sizing: border-box;
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-2 {
    box-sizing: border-box;
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-3 {
    box-sizing: border-box;
    /* background-color: yellow; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-3>.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-3>.frame>img {
    height: 45%;
    width: auto;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.w-tab-portal-transactions-screen>.navbar>.box.bar-3>.frame>img:hover {
    filter: invert(25%);
    transition: 0.3s ease-out;
}



/* wrapper */
.w-tab-portal-transactions-screen>.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5vh;
    /* background-color: red; */
}


/* wrapper - title */
.w-tab-portal-transactions-screen>.wrapper>h1 {
    font-size: 6vh;
    margin-bottom: 4vh;
    /* background-color: blue; */
}


/* wrapper - dashboard */
.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 87%;
    height: 30vh;
    padding-inline: 2vw;
    margin-top: 6h;
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    padding-inline: 0.5vw;
    /* border: 1px dashed grey; */
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding-block: 2vh;
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper:hover {
    box-shadow: 1px 1px 4px rgba(101, 100, 100, 0.463);
    border-radius: 10px;
    transition: 0.3s ease-out;
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    height: 65%;
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 100%; */

    overflow: hidden;
    width: 7vw;
    height: 7vw;
    /* background-color: blue; */
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.images>.frame>img {
    height: 100%;
    filter: invert(10%);
}


.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 35%;
    width: 90%;
    /* border: 1px solid red; */
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>h2 {
    text-align: center;
    font-size: larger;
}

.w-tab-portal-transactions-screen>.wrapper>.cardholder.dashboard-menu>.card.dashboard-menu>.object-wrapper>.texts>p {
    text-align: center;
    font-size: small;
    width: 100%;
    /* background-color: rgb(31, 31, 31);; */
}