.w-pc-error-snack-bar{
    background-color: rgba(0, 0, 0, 0.936);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    padding-block: 3.5vh;
    width :100vw;
    transition: 3s ease;
    opacity: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 100%;
    z-index: 9999;
}
.w-pc-error-snack-bar>h1{
    text-align: center;
}
.w-pc-error-snack-bar.hide{
    z-index: 0;
    opacity: 0%;
}

/* tab device */
.w-tab-error-snack-bar {
    background-color: black;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    padding-block: 3.5vh;
    width: 100vw;
    transition: 3s ease;
    opacity: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 100%;
    z-index: 9999;
}

.w-tab-error-snack-bar>p {
    text-align: center;
    font-size: 1.7vh;
}

.w-tab-error-snack-bar.hide {
    z-index: 0;
    opacity: 0%;
}

/* mobile device */

.w-mob-error-snack-bar {
    background-color: black;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    padding-block: 3.5vh;
    width: 100vw;
    transition: 3s ease;
    opacity: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 100%;
    z-index: 9999;
}

.w-mob-error-snack-bar>p {
    text-align: center;
    font-size: 1.7vh;
}

.w-mob-error-snack-bar.hide {
    z-index: 0;
    opacity: 0%;
}