 @font-face {
   font-family: 'Poppins';
   src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
 }

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p{
  padding: 0;
  margin: 0;
}

/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
  /* White with 30% opacity */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  /* Semi-transparent color */
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* Optional: white border with 50% opacity */
}